<!-- =========================================================================================
	File Name: App.vue
	Description: Main vue file - APP
	----------------------------------------------------------------------------------------
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: Pixinvent
	Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div id="app" :class="vueAppClasses">
    <router-view @setAppClasses="setAppClasses" />
  </div>
</template>

<script>
  import themeConfig from '@/../themeConfig.js'
  import firebase from 'firebase'

  export default {
    data() {
      return {
        vueAppClasses: [],
      }
    },
    watch: {
      '$store.state.theme'(val) {
        this.toggleClassInBody(val)
      },
      '$vs.rtl'(val) {
        document.documentElement.setAttribute("dir", val ? "rtl" : "ltr")
      }
    },
    methods: {
      toggleClassInBody(className) {
        if (className == 'dark') {
          if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
          document.body.classList.add('theme-dark')
        } else if (className == 'semi-dark') {
          if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark')
          document.body.classList.add('theme-semi-dark')
        } else {
          if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark')
          if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
        }
      },
      getNotification(){
        this.$root.$emit('getNotification') //like this
      },
      setAppClasses(classesStr) {
        this.vueAppClasses.push(classesStr)
      },
      handleWindowResize() {
        this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

        // Set --vh property
        document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
      },
      handleScroll() {
        this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY)
      }
    },
    mounted() {
      this.toggleClassInBody(themeConfig.theme)
      this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    async created() {

      let dir = this.$vs.rtl ? "rtl" : "ltr"
      document.documentElement.setAttribute("dir", dir)

      window.addEventListener('resize', this.handleWindowResize)
      window.addEventListener('scroll', this.handleScroll)

      //firebase
      
      navigator.serviceWorker
        .register("firebase-messaging-sw.js")
        .then(function (registration) {
          firebase
            .messaging().useServiceWorker(registration);
        })
        .catch(function (err) {});

      // to store token value
      let token = '';

      await firebase
        .messaging()
        .requestPermission()
        .then(function () {
          
          return firebase.messaging().getToken();
        })
        .then(function (fcm_token) {
            token = fcm_token;
        })
        .catch(function (error) {
          
        })
      // store the token
      await this.$store.dispatch("auth/storeFcmToken", {token: token})

      firebase.messaging().onMessage((payload) => {
        let notificationTitle = "طلبية جديدة";
        let notificationOptions = {
            body: payload.data.body,
            icon: payload.data.icon,
            image: payload.data.image,
            requireInteraction: true,
        };
        if(payload.data.type !== "chat"){
          this.$vs.notify({
          title: "إشعار", //payload.notification.title
          text:  "لقد تمت إضافة طلب " ,//payload.notification.body
          color:'warning',
          iconPack: 'feather',
          icon:'icon-bell'
          })
        }

        this.getNotification()
      })

    },
    destroyed() {
      window.removeEventListener('resize', this.handleWindowResize)
      window.removeEventListener('scroll', this.handleScroll)
    },
  }

</script>

import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
  const ifAuthenticated = (to, from, next) => {

    if (localStorage.getItem("token")) {
      return next();
    }
    next('/');
  };

  const ifAdmin = async(to, from, next) => {
    if ( !JSON.parse(localStorage.getItem('limited'))   ) {
      return next();
    }

    next('/home');
  };


let children = [
  {
    path: '/home',
    name: 'home',
    component: () => import('./views/Home.vue')
  },
  {
    path: '/products',
    beforeEnter: ifAdmin,
    name: 'products',
    component: () => import('./views/ecommerce/products/Products.vue')
  },
  {
    path: '/banners',
    beforeEnter: ifAdmin,
    name: 'banners',
    component: () => import('./views/banners/Banners.vue')
  },
  {
    path: '/banners/new',
    beforeEnter: ifAdmin,
    name: 'new-banner',
    component: () => import('@/views/banners/NewBanner.vue')
  },
  {
    path: '/banners/edit/:id',
    beforeEnter: ifAdmin,
    name: 'edit-banner',
    component: () => import('@/views/banners/EditBanner.vue')
  },
  {
    path: '/products/new',
    beforeEnter: ifAdmin,
    name: 'new-product',
    component: () => import('@/views/ecommerce/products/NewProduct.vue')
  },
  {
    path: '/products/edit',
    beforeEnter: ifAdmin,
    name: 'edit-product',
    component: () => import('@/views/ecommerce/products/EditProduct.vue'),
    props:true
  },
  {
    path: '/orders',
    name: 'orders',
    query: {
      page: 1,

    },
    component: () => import('./views/ecommerce/orders/Orders.vue')
  },
  {
    path: '/brands',
    name: 'brands',
    query: {
      page: 1,
    },
    component: () => import('./views/brands/BrandsList.vue')
  },
  {
    path: '/categories',
    name: 'categories',

    component: () => import('./views/categories/List.vue')
  },
  {
    path: '/archived-orders',
    name: 'archived-orders',

    component: () => import('./views/archive/orders.vue')
  },
  {
    path: '/brands-create',
    name: 'brands-create',

    component: () => import('./views/brands/BrandAdd.vue')
  },
  {
    path: '/brands/edit/:id',
    name: 'brands-edit',
    component: () => import('./views/brands/BrandEdit.vue')
  },
  {
    path: '/category/edit/:id',
    name: 'category-edit',
    component: () => import('./views/categories/Update.vue')
  },
  {
    path: '/transactions',
    name: 'transactions',
    query: {
      page: 1,

    },
    component: () => import('./views/ecommerce/transactions/index.vue')
  },
  {
    path: '/requests',
    name: 'requests',
    query: {
      page: 1,

    },
    component: () => import('./views/MaintenanceRequest/index.vue')
  },
  {
    path: '/orders/new',
    name: 'new-order',
    component: () => import('./views/ecommerce/orders/NewOrder.vue')
  },
  {
    path: '/orders/edit/:id',
    name: 'edit-order',
    component: () => import('./views/ecommerce/orders/EditOrder.vue')
  },
  {
    path: '/orders/invoice',
    name: 'invoice',
    component: () => import('@/views/pages/Invoice.vue'),
    props:true
  },
  //packages
  {
    path: '/packages',
    beforeEnter: ifAdmin,
    name: 'packages',
    component: () => import('./views/ecommerce/packages/Packages.vue')
  },
  {
    path: '/packages/new',
    beforeEnter: ifAdmin,
    name: 'new-package',
    component: () => import('@/views/ecommerce/packages/NewPackage.vue')
  },
  {
    path: '/packages/edit',
    beforeEnter: ifAdmin,
    name: 'edit-package',
    component: () => import('@/views/ecommerce/packages/EditPackage.vue'),
    props:true
  },
  {
    path: '/pages/error-404',
    name: 'page-error-404',
    component: () => import('@/views/pages/Error404.vue')
  },
//contests
  {
    path: '/contests',
    beforeEnter: ifAdmin,
    name: 'contests',
    component: () => import('./views/contests/Contests.vue')
  },
  {
    path: '/contests/new',
    beforeEnter: ifAdmin,
    name: 'new-contest',
    component: () => import('@/views/contests/NewContest.vue')
  },
  {
    path: '/contests/edit',
    beforeEnter: ifAdmin,
    name: 'edit-contest',
    component: () => import('@/views/contests/EditContest.vue')
  },
  //fastupdate
  {
    path: '/fastUpdate',
    name: 'fastUpdate',
    component: () => import('./views/tools/FastUpdate.vue')
  },
  {
    path: '/coupons',
    beforeEnter: ifAdmin,
    name: 'coupons',
    component: () => import('./views/coupons/Coupons.vue')
  },
  {
    path: '/contests-results',
    beforeEnter: ifAdmin,
    name: 'contestsResults',
    component: () => import('./views/contests/ContestsResults.vue')
  },
  {
    path: '/archive',
    beforeEnter: ifAdmin,
    name: 'Archive',
    component: () => import('./views/archive/Archive.vue')
  },
  {
    path: '/users',
    beforeEnter: ifAdmin,
    name: 'users',
    component: () => import('./views/users/Users.vue')
  },
  {
    path: '/offer-products',
    beforeEnter: ifAdmin,
    name: 'offerProducts',
    component: () => import('./views/offerProduct/index.vue')
  },
  {
    path: '/delivery',
    beforeEnter: ifAdmin,
    name: 'delivery',
    component: () => import('./views/delivery/Users.vue')
  },
  {
    path: '/admins',
    beforeEnter: ifAdmin,
    name: 'admins',
    component: () => import('./views/admins/Admins.vue')
  },
  {
    path: '/admins/new',
    beforeEnter: ifAdmin,
    name: 'new-admin',
    component: () => import('./views/admins/newAdmin.vue')
  },
  //chat
  {
    path: '/chat',
    beforeEnter: ifAdmin,
    name: 'chat',
    component: () => import('./views/chat/Chat.vue')
  }
]


const router = new Router({
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    };
  },
  routes: [{
      path: '/',
      name: 'login',
      component: () => import('./views/login/Login.vue'),
    },
    {
      path: '/main',
      component: () => import('./layouts/main/Main.vue'),
      beforeEnter: ifAuthenticated,
      children: children
    },
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ],

})
router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = "none";
  }
});
export default router;

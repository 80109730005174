/*=========================================================================================
  File Name: moduleChatActions.js
  Description: Chat Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'

export default {

  setChatSearchQuery ({ commit }, query) {
    commit('SET_CHAT_SEARCH_QUERY', query)
  },


  updateAboutChat ({ commit, rootState }, value) {
    commit('UPDATE_ABOUT_CHAT', {
      rootState,
      value
    })
  },

  updateStatusChat ({ commit, rootState }, value) {
    commit('UPDATE_STATUS_CHAT', {
      rootState,
      value
    })
  },

  // API CALLS
  sendChatMessage ({ getters, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('https://0a4ww0hxpk.execute-api.eu-central-1.amazonaws.com/dev/messages/add', payload)
        .then((response) => {
          
          if(response.data.statusCode !== 200) throw response.data;
          commit('UPDATE_ROOM_LATTEST', payload.roomID)
  
          commit('SEND_CHAT_MESSAGE', payload)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  // Get contacts from server. Also change in store
  fetchContacts ({ commit }) {

    return new Promise((resolve, reject) => {
      axios.post('https://0a4ww0hxpk.execute-api.eu-central-1.amazonaws.com/dev/rooms/get', {} )
        .then((response) => {
          
          if(response.data.statusCode !== 200) throw response.data;

          commit('UPDATE_CONTACTS', response.data.body)
          resolve(response)
        })
        .catch((error) => {reject(error) })
    })


  },

  // Get chat-contacts from server. Also change in store
  fetchChatContacts ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/apps/chat/chat-contacts', {params: {q: ''}})
        .then((response) => {
          commit('UPDATE_CHAT_CONTACTS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  // Get chats from server. Also change in store
  fetchChats ({ commit },id) {
    return new Promise((resolve, reject) => {
      axios.post('https://0a4ww0hxpk.execute-api.eu-central-1.amazonaws.com/dev/messages/get',{
        "Filter": {
          "ByRoom": {
            "id": id
          }
        }
      })
        .then((response) => {
          
          if(response.data.statusCode !== 200) throw response.data;
          commit('UPDATE_CHATS',{ room: id, 
            isPinned: true,
            msg:response.data.body
          })
          commit('MARK_SEEN_MSGS', id)
          
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  markSeenAllMessages ({ getters, commit }, id) {

    return new Promise((resolve, reject) => {
      axios.post('/api/apps/chat/mark-all-seen', {id})
        .then((response) => {
          commit('MARK_SEEN_ALL_MESSAGES', {
            id,
            chatData: getters.chatDataOfUser(id)
          })
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  toggleIsPinned ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/apps/chat/set-pinned/', {contactId: payload.id,
        value: payload.value})
        .then((response) => {
          commit('TOGGLE_IS_PINNED', payload)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  OpenChatSokcet({ dispatch, getters, commit, state ,rootState}, notify ){
    
    if (state.socket) { 
      state.socket.close()
    }

    
    let MsgsSocket = new WebSocket(`wss://ab8wrfl9g7.execute-api.eu-central-1.amazonaws.com/production?Authorization=${btoa(localStorage.getItem("token"))}&roomID=*`)
    
    state.socket = MsgsSocket
    MsgsSocket.onclose = () => {
      
    }

    MsgsSocket.onopen = () => {
      

    }
    MsgsSocket.onerror = (e) => {
      
      throw e;
    }

    MsgsSocket.onmessage = async (msg) => {
      
      msg = JSON.parse(msg.data)
      let ActiveChat = getters['chatDataOfUser']()

      if(msg.roomID == ActiveChat.room){
        let NewChat = JSON.parse(JSON.stringify( ActiveChat ))
        NewChat.msg = [...NewChat.msg,msg]
        commit('UPDATE_CHATS', NewChat )

      }else{
        commit('MARK_UNSEEN_MSGS',msg.roomID )
      }
      if(rootState.AppActiveUser.id !== msg.author.id){
        notify({
          title: "رسالة جديد  : "+msg.roomID, //payload.notification.title
          text:  msg.content.length > 15 ? msg.content.slice(0,12)+" ..." : msg.content,//payload.notification.body
          color:'primary',
          iconPack: 'feather',
          icon:'icon-bell'
        })
      }

      
      await dispatch("fetchContacts")
    }    
  },


  // Disable Room ( Update Room Status to False )
  UpdateRoomStatus( { dispatch, getters, commit, state ,rootState}, room_id ){
    //
    
    return new Promise( (resolve, reject) => {
      axios.post('https://0a4ww0hxpk.execute-api.eu-central-1.amazonaws.com/dev/rooms/update-status', {
        "Filter": {
          "ByRoom": {
            "id": room_id
          }
        },
        "Update":[
          {
            "name":"status",
            //by default it will be false
            "value":false
          }
        ]
      })
        .then( async (response) => {
          
          if(response.data.statusCode !== 200) throw response.data;

          await dispatch("fetchContacts")
          resolve(response.data)
        })
        .catch((error) => {
           reject(error) })
    })
    
  }
}

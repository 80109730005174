/*=========================================================================================
  File Name: moduleChatGetters.js
  Description: Chat Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


// import contacts from '@/views/apps/chat/contacts'

export default {

  chatDataOfUser: state => id => {
    return state.chats
  },


  chatContacts: (state, getters) => {

    const chatContacts = state.chatContacts.filter((contact) => contact.displayName.toLowerCase().includes(state.chatSearchQuery.toLowerCase()))
/*
    chatContacts.sort((x, y) => {
      const timeX = getters.chatLastMessaged(x.uid).time
      const timeY = getters.chatLastMessaged(y.uid).time
      return new Date(timeY) - new Date(timeX)
    })
*/
    return chatContacts
    /*chatContacts.sort((x, y) => {
      const chatDataX = getters.chatDataOfUser(x.uid)
      const chatDataY = getters.chatDataOfUser(y.uid)
      if (chatDataX && chatDataY) return chatDataY.isPinned - chatDataX.isPinned
      else return 0
    })*/

  },
  todaycontact : (state) => state.contacts.filter( (contact) => (contact.id + contact.users[0].info.username).includes(state.chatSearchQuery.toLowerCase()) ).sort( (x,y)=> y.latestUpdate-x.latestUpdate).sort( (x,y)=> y.NoNotSeentMsgs-x.NoNotSeentMsgs).sort( (x,y)=> y.status - x.status).filter(room => isSameDay( new Date().getTime(), room.latestUpdate)),
  contacts: (state) => state.contacts.filter( (contact) => (contact.id + contact.users[0].info.username).includes(state.chatSearchQuery.toLowerCase()) ).sort( (x,y)=> y.latestUpdate-x.latestUpdate).sort( (x,y)=> y.NoNotSeentMsgs-x.NoNotSeentMsgs).sort( (x,y)=> y.status - x.status).filter(room => !isSameDay( new Date().getTime(), room.latestUpdate)),
  contact: (state) => contactId => state.contacts.find((contact) => contact.id === contactId),
  chats: (state) => state.chats,
  chatUser: (state, getters, rootState) => id => state.contacts.find((contact) => contact.uid === id) || rootState.AppActiveUser,

  /*
  chatLastMessaged: (state, getters) => id => {
    if (getters.chatDataOfUser(id)) return getters.chatDataOfUser(id).msg.slice(-1)[0]
    else return false
  },

  chatUnseenMessages: (state, getters) => id => {
    let unseenMsg = 0
    const chatData = getters.chatDataOfUser(id)
    if (chatData) {
      chatData.msg.map((msg) => {
        if (!msg.isSeen && !msg.isSent) unseenMsg++
      })
    }
    return unseenMsg
  }
  */
}
/*=========================================================================================
  File Name: moduleChatGetters.js
  Description: Chat Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
function isSameDay (time_to, time_from) {
  const date_time_to = new Date(Number.parseInt(time_to))
  const date_time_from = new Date(Number.parseInt(time_from))
  return date_time_to.getFullYear() === date_time_from.getFullYear() &&
            date_time_to.getMonth() === date_time_from.getMonth() &&
            date_time_to.getDate() === date_time_from.getDate()
}
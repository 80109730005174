// axios
import axios from 'axios'

const baseURL = process.env.VUE_APP_BASE_URL
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
export default axios.create({
  baseURL: baseURL,
  // You can add your headers here
  headers:{
    'Accept' : 'application/json',
  }
  
})

/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"
import moduleAuth from './auth/moduleAuth.js'
import moduleChat from './chat/moduleChat.js'
import banners from './banners/moduleBanner.js'
import transactions from './transactions/moduleTransaction.js'
import categories from './category/moduleCategory.js'


Vue.use(Vuex)


export default new Vuex.Store({
    getters,
    mutations,
    state,
    actions,
    modules: {
      auth: moduleAuth,
     chat: moduleChat,
     banners,transactions,categories
  },
    strict: process.env.NODE_ENV !== 'production'
})

import axios from "@/axios.js"

export default {
  fetchBanners({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/user/banners/index`)
        .then((response) => {
          commit('SET_BANNERS', response.data.banners);
          resolve(response.data.banners)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  deleteBanner({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/user/banners/delete/${itemId}`)
        .then((response) => {
          commit('DELETE_BANNER', itemId);
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  createBanner({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/user/banners/store`, payload)
        .then((response) => {
          commit('ADD_NEW_BANNER', response.data.data);
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
}

import axios from '@/axios.js'

function filterToQuery(filters){
  const handlers = {
     date : (dates)=>dates.map((date,index)=>`${index == 0 ? "date_min":"date_max"}[created_at]=${date}`).join('&'),
     brand : (val)=>`brand_id=${val}&exact[items.product.brand_id]=${val}` 
  }

  return `?${Object.keys(filters).map(filter=>{
    if(handlers[filter] && filters[filter] ) return handlers[filter](filters[filter]);
  }).join('&')}`

}

const actions = {

    // /////////////////////////////////////////////
    // COMPONENTS
    // /////////////////////////////////////////////
    getDashboardStatistics({commit,state},){
      
      return new Promise((resolve,reject)=>{
       let $query = filterToQuery(state.DashboardStatisticsFilter)
        axios.get(`api/dashboard/statistics${$query}'`)
        .then((response)=>{
          commit('UPDATE_DASHBOARD_STATISTICS',response.data.dashboard)
          resolve(response)
        }).catch((error)=>{
          reject(error)
        })
      })
      
    },

    getDashboardTable({commit,state},){
      
      return new Promise((resolve,reject)=>{
       let $query = filterToQuery(state.DashboardStatisticsFilter)
        axios.get(`api/dashboard/statistics/table${$query}'`)
        .then((response)=>{
          commit('UPDATE_DASHBOARD_STATISTICS_TABLE',response.data.categories)
          resolve(response)
        }).catch((error)=>{
          reject(error)
        })
      })
      
    },
    getDashboardProductsTable({commit,state},params){
      
      return new Promise((resolve,reject)=>{
       let $query = filterToQuery(state.DashboardStatisticsFilter)
        axios.get(`api/dashboard/statistics/table/products${$query}'`,{params:params})
        .then((response)=>{
          commit('UPDATE_DASHBOARD_STATISTICS_TABLE_PRODUCTS',response.data.products)
          resolve(response)
        }).catch((error)=>{
          reject(error)
        })
      })
      
    },
    getCodeReport({commit,state},){
      
      return new Promise((resolve,reject)=>{
        axios.get(`api/dashboard/reports/code-report`)
        .then((response)=>{
          resolve(response)
        }).catch((error)=>{
          reject(error)
        })
      })
      
    },

    getDashboardOrdersByDay({commit,state},){
      
      return new Promise((resolve,reject)=>{
       let $query = filterToQuery(state.DashboardStatisticsFilter)
        axios.get(`api/dashboard/statistics/ordersbyTimePeriod${$query}'`)
        .then((response)=>{
          commit('UPDATE_DASHBOARD_ORDERS_BY_DAY',response.data.ordersByDay)
          resolve(response)
        }).catch((error)=>{
          reject(error)
        })
      })
      
    },
    getDashboardOrdersByCategory({commit,state},){
      
      return new Promise((resolve,reject)=>{
       let $query = filterToQuery(state.DashboardStatisticsFilter)
        axios.get(`api/dashboard/statistics/ordersByCategoriesColumn${$query}'`)
        .then((response)=>{
          commit('UPDATE_DASHBOARD_ORDERS_BY_CATEGORY',response.data.categories)
          resolve(response)
        }).catch((error)=>{
          reject(error)
        })
      })
      
    },

    
    getAllProducts({commit}){
      return new Promise((resolve,reject)=>{
          axios.get(`api/dashboard/products/products`)
          .then((response)=>{
              commit('SET_PRODUCTS',response.data)
              resolve(response)
          }).catch((error)=>{
              reject(error)
          })
      })
  },
  getAllUsers({commit}){
    return new Promise((resolve,reject)=>{
        axios.get(`api/dashboard/users/users`)
        .then((response)=>{
            commit('SET_USERS',response.data)
            resolve(response)
        }).catch((error)=>{
            reject(error)
        })
    })
},
getAllCoupons({commit}){
  return new Promise((resolve,reject)=>{
      axios.get(`api/dashboard/coupons/coupons`)
      .then((response)=>{
          commit('SET_COUPONS',response.data)
          resolve(response)
      }).catch((error)=>{
          reject(error)
      })
  })
},

  getAllPackages({commit}){
    return new Promise((resolve,reject)=>{
        axios.get(`api/dashboard/packages/packages`)
        .then((response)=>{
            commit('SET_PACKAGES',response.data.packages)
            resolve(response)
        }).catch((error)=>{
            reject(error)
        })
    })
},
    // Vertical NavMenu
    updateVerticalNavMenuWidth({ commit }, width) {
      commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
    },

    // VxAutoSuggest
    updateStarredPage({ commit }, payload) {
      commit('UPDATE_STARRED_PAGE', payload)
    },

    // The Navbar
    arrangeStarredPagesLimited({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_LIMITED', list)
    },
    arrangeStarredPagesMore({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_MORE', list)
    },

    // /////////////////////////////////////////////
    // UI
    // /////////////////////////////////////////////

    toggleContentOverlay({ commit }) {
      commit('TOGGLE_CONTENT_OVERLAY')
    },
    updateTheme({ commit }, val) {
      commit('UPDATE_THEME', val)
    },

    // /////////////////////////////////////////////
    // User/Account
    // /////////////////////////////////////////////

    updateUserInfo({ commit }, payload) {
      commit('UPDATE_USER_INFO', payload)
    },
}

export default actions

/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework

import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax)

// firebase
import firebase from 'firebase'
var firebaseConfig = {
  apiKey: "AIzaSyCTl9DTBdhuFmG_Lb2Ypz04RkhvRTzLPc8",
  authDomain: "almithaly-9d895.firebaseapp.com",
  projectId: "almithaly-9d895",
  storageBucket: "almithaly-9d895.appspot.com",
  messagingSenderId: "610254803995",
  appId: "1:610254803995:web:8ccbdadb350d5f3ac4d4be",
  measurementId: "G-H41TNF852W"
};
firebase.initializeApp(firebaseConfig);
// axios
import axios from "./axios.js"
Vue.prototype.$http = axios

// Theme Configurations
import '../themeConfig.js'


// Globally Registered Components
import './globalComponents.js'

// Vuexy Admin Filters
import './filters/filters'

// Styles: SCSS
import './assets/scss/main.scss'



// Tailwind
import '@/assets/css/main.css'


// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'

import Common from './common-plugin';
Vue.use(Common);

//fontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSnowflake } from '@fortawesome/free-solid-svg-icons'

library.add(faSnowflake)

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

import VeeValidate, { Validator } from 'vee-validate';
import attributesAR from 'vee-validate/dist/locale/ar'
import attributesEn from 'vee-validate/dist/locale/en'
Validator.localize('ar',attributesAR);
Vue.use(VeeValidate, {
  locale: 'ar',
  errorBagName: 'errors',
  fieldsBagName: 'inputs',
  dictionary: {
    translationsEn: { attributes: attributesEn },
    translationsAR: { attributes: attributesAR }
  }});

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'


// Feather font icon
require('./assets/css/iconfont.css')


Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

import axios from "@/axios.js"

export default {
    fetchTransactions({commit},params){
        return new Promise((resolve,reject)=>{
            axios.get(`api/transactions`,{params:params})
            .then((response)=>{
                commit("SET_META_DATA", response.data.transactions.meta);
                commit(`SET_TRANSACTIONS`,response.data.transactions.data);
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
}
/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import navbarSearchAndPinList from "@/layouts/components/navbar/navbarSearchAndPinList"
import themeConfig from "@/../themeConfig.js"
import colors from "@/../themeConfig.js"


// /////////////////////////////////////////////
// Variables
// /////////////////////////////////////////////
const is_touch_device = () => {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
  const mq = function (query) {
    return window.matchMedia(query).matches
  }

  if ('ontouchstart' in window || window.DocumentTouch) {
    return true
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
  return mq(query)
}

const userDefaults = {
  username: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).username : "مشرف", // From Auth
  full_path: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).full_path : require("@/assets/images/portrait/small/admin.png"), // From Auth
  status: "online", // From Auth
  limited: true,
  id: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).id : -1
}

// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
  AppActiveUser: userDefaults,
  DashboardStatistics: {},
  DashboardStatisticsFilter: {
    date: null,
    brand: null
  },
  DashboardStatisticsTable :[],
  DashboardStatisticsTableProducts :[],
  
  DashboardOrdersByDay:[],
  DashboardOrdersByCategory:[],

  products: [],
  packages: [],
  users: [],
  coupons: [],
  bodyOverlay: false,
  isVerticalNavMenuActive: true,
  mainLayoutType: themeConfig.mainLayoutType || "vertical",
  navbarSearchAndPinList: navbarSearchAndPinList,
  reduceButton: themeConfig.sidebarCollapsed,
  verticalNavMenuWidth: "default",
  verticalNavMenuItemsMin: false,
  scrollY: 0,
  starredPages: navbarSearchAndPinList["pages"].data.filter((page) => page.is_bookmarked),
  theme: themeConfig.theme || "light",
  themePrimaryColor: colors.primary,
  is_touch_device: is_touch_device(),

  // Can be used to get current window with
  // Note: Above breakpoint state is for internal use of sidebar & navbar component
  windowWidth: null,
}

export default state

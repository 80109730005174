import axios from "@/axios.js"

export default {
    fetchCategories({commit}){
        return new Promise((resolve,reject)=>{
            axios.get(`/api/dashboard/categories/index`)
            .then((response)=>{
                commit('SET_CATEGORIES',response.data.categories);
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    fetchCategory({commit},id){
        return new Promise((resolve,reject)=>{
            axios.get(`/api/dashboard/categories/${id}`)
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    updateCategory({commit},payload){
        return new Promise((resolve,reject)=>{
            payload.set("_method", 'put' );
            axios.post(`/api/dashboard/categories/update/${payload.get('id')}`,payload)
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
}